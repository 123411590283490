import { setCurrentHrDocsThunkAction } from "./setCurrentHrDocs.thunk.action";
import { getUserHrDocsThunkAction } from "./getUserHrDocs.thunk.action";

import $api from "../../../../services/axiosService";


export const setViewedHrDocumentThuunkAction =
    (packageId, docId, workerId, typeViewTo, props, isViewed, file) =>
        async (dispatch) => {
            try {
                // const file = await $api.get(
                //     `v2/directum/document/file/${packageId}/${docId}/${workerId}`,
                //     { responseType: "blob" },
                // );
                // window.open("api/v2/directum/document/file/25916642/25916643/150431/0");
                // window.open(`${file.url}`);
                const document = await $api.get(
                    `${file.url}`,
                    { responseType: "blob" },
                );

                const blobLink = URL.createObjectURL(
                    new Blob([document.data],
                        { type: `${document.headers["content-type"]}` },
                    ),
                );

                // FileSaver.saveAs(blobLink, file.title);
                // console.log(blobLink);
                // window.open(blobLink);

                //Add authentication headers in URL


                if (file.url && (file.fileType || file.fileType === 0) && blobLink) {
                    window.open(blobLink);
                    if (!isViewed) {
                        // const response =
                        await $api.post(
                            `v2/directum/document/mark/asread/${packageId}/${docId}/${workerId}`,
                        );
                    }
                    dispatch(
                        setCurrentHrDocsThunkAction(
                            packageId,
                            workerId,
                            typeViewTo,
                        ),
                    );
                    dispatch(getUserHrDocsThunkAction());
                } else {
                    props.closeHandlerPackages();
                    props.setErrDescription(
                        "Что-то пошло не так, повторите попытку позже!",
                    );
                    props.helper();
                }
            } catch (e) {
                props.closeHandlerPackages();
                props.setErrDescription(
                    "Что-то пошло не так, повторите попытку позже!",
                );
                props.helper();
            }
        };
