import { $api, $apiMultipart } from "./request.config";

import axios from "axios";

//
// $apiMultipart.interceptors.request.use((config) => {
//     config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
//     return config
// })
// $apiMultipart.interceptors.response.use((config) => {
//     return config
// }, async (error) => {
//     const originalRequest = error.config
//     // console.log(error)
//     if (error.response.status === 401 && error.config && !error.config._isRetry) {
//         originalRequest._isRetry = true
//         try {
//             const id = localStorage.getItem('userId')
//             const authToken = localStorage.getItem('token')
//             const refreshToken = localStorage.getItem('refreshToken')
//             const response = await $apiMultipart.post('/v1/auth/refresh',
//                 {id: id, authToken: authToken, refreshToken: refreshToken, consumer: 1})
//             if (response.status) {
//                 localStorage.setItem('token', response.data.token)
//                 localStorage.setItem('refreshToken', response.data.refreshToken)
//             }
//             return $apiMultipart.request(originalRequest)
//         } catch (e) {
//             // console.log('НЕ АВТОРИЗОВАН', e)
//         }
//     }
//     throw error
// })
//
// export default $apiMultipart

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};


$apiMultipart.interceptors.request.use((config) => {

    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

    return config;

}, (error) => {

    // console.log("interceptor error:", error)

    return Promise.reject(error);

});


$apiMultipart.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        const originalRequest = err.config;

        if (err.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers["Authorization"] = "Bearer " + token;
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function(resolve, reject) {
                const id = localStorage.getItem("userId");
                const authToken = localStorage.getItem("token");
                const refreshToken = localStorage.getItem("refreshToken");
                $api
                    .post("v1/auth/refresh", {
                        refreshToken, id, authToken, consumer: 1,
                    })
                    .then(({ data }) => {
                        axios.defaults.headers.common["Authorization"] = "Bearer " + data.token;
                        originalRequest.headers["Authorization"] = "Bearer " + data.token;
                        processQueue(null, data.token);
                        localStorage.setItem("refreshToken", data.refreshToken);
                        localStorage.setItem("token", data.token);
                        resolve(axios(originalRequest));
                    })
                    .catch(err => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(err);
    },
);

export default $apiMultipart;
