import { $api } from "../../../../services/request.config";
import {
    DISMISSAL_REVOCATION_UPDATE_DRAFT,
    MATERNITY_UPDATE_DRAFT,
    STUDY_LEAVE_UPDATE_DRAFT,
} from "../../../../enums/Request/applicationUrls";

function applicationUrl(option, uid) {
    switch (option) {
    case 5:
        return STUDY_LEAVE_UPDATE_DRAFT;
    case 6:
        return MATERNITY_UPDATE_DRAFT;
    case 14:
        return DISMISSAL_REVOCATION_UPDATE_DRAFT;
    default:
        return "v1/leaverequest";
    }
}

export const updateCurrentRequestThunk =
    (request, modal, setIsUploading, setModalError) => async (dispatch) => {
        try {
            let response;
            const temp = applicationUrl(request.leaveTypeId);
            if (request.leaveTypeId === 7) {
                console.log("WWWOOORRRKKK");
                response = await $api.post("v1/requests/leaves/parental", {
                    ...request,
                    uid: request.uid,
                });
                console.log(response);
                if (response.status === 200) {
                    modal(true);
                }
            } else {
                response = await $api.put(`${temp}`, {
                    ...request,
                    uid: request.uid,
                });
                if (response.status === 200) {
                    modal(true);
                }
            }

        } catch (error) {
            console.log("ERROR");
            modal && modal(false);
            setModalError({
                sign: false,
                validation: false,
                status: true,
                saving: true,
            });


        }
    };
