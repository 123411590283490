import { dataHeader } from "./VacationHolidaysPage.data";

import Header from "../../layout/Header/Header";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";
import Footer from "../../layout/Footer/Footer";
import MultiWidget from "../../common/MultiWidget/MultiWidget";
import { leavesWidgetsThunkAction } from "../../../redux/actions/LeavesWorkTimeShifts/thunk/leavesWidgetsThunkAction";
import { resetLeavesWidgetsAction } from "../../../redux/actions/LeavesWorkTimeShifts/leavesWidgetsAction";
import { Modal } from "../../common/Modal/Modal";
import AnnualCalendar from "../../common/Modal/AnnualCalendar/AnnualCalendar";
import LeavesService from "../../../frontend.services/Leaves.service";
import { isTerminal } from "../../../constants";
import { AnnualCalendarForTerminal } from "../../common/componentsTerminal/Calendar/AnnualCalendarForTerminal";
import { getActualMonthForTerminalCalendar } from "../../../utils/getActualMonthForTerminalCalendar";

import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

isTerminal ? import("./VacationHolidaysPageTerminal.scss") : import("./VacationHolidaysPage.scss");

const VacationHolidaysPage = () => {

    const dispatch = useDispatch();

    const leaves = useSelector((state) => state.workTime.leaves);

    const [leavesCurrent, setLeavesCurrent] = useState(leaves);
    const [modalAnnual, toggleModalAnnual] = useState(false);
    const [leaveType, setLeaveType] = useState({ type: null, data: null });
    const [toggleState, setToggleState] = useState(1);

    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [firstDisplayedMonth, setFirstDisplayedMonth] = useState(1);

    useEffect(() => {
        setFirstDisplayedMonth(getActualMonthForTerminalCalendar(modalPosition.x));
    }, [modalPosition]);

    const toggleTab = (index) => {
        setToggleState(index);
        dispatch(resetLeavesWidgetsAction());
        if (index === 2) {
            dispatch(
                leavesWidgetsThunkAction(LeavesService.getCurrentYear() - 1),
            );
        } else {
            dispatch(leavesWidgetsThunkAction(LeavesService.getCurrentYear()));
        }
    };

    function clickHandlerAnnual(type, i, e) {
        const { clientX, clientY } = e;
        setModalPosition({ x: clientX, y: clientY });
        setLeaveType({
            type: type,
            data: leavesCurrent.leavesPeriodForDayPicker[i],
        });
        toggleModalAnnual(!modalAnnual);
    }

    function closeHandlerAnnual() {
        toggleModalAnnual(false);
    }

    useEffect(() => {
        dispatch(leavesWidgetsThunkAction(LeavesService.getCurrentYear()));
    }, [dispatch]);

    useEffect(() => {
        setLeavesCurrent(leaves);
    }, [leaves]);
    // TODO: check styles: was wrapped
    return leavesCurrent ? (
        <>
            <Header />
            <Breadcrumbs />
            <div className={isTerminal ? "page-wrapper" : "leaves-page"}>
                <h1 className={"sub-header"}>Отпуска и дни отдыха</h1>
                <div className={isTerminal ? "multi-wdget__wrapper" : "leaves-multi-widget"}>
                    {/* TODO: page-wrapper and multi-wdget__wrapper
                        these variables are needed for the terminal
                        version and do not harm the desktop layout */}
                    <MultiWidget
                        data={leavesCurrent.widgets}
                        styleParent="leaves-widgets"
                        // subHeader="Отпуска и дни отдыха"
                        isBackButtonEnabled
                    />
                </div>
                <div className="navMenu">
                    <div
                        onClick={() => toggleTab(2)}
                        className={
                            toggleState === 2
                                ? "documents-menu-item"
                                : "documents-menu-item-active"
                        }
                    >
                        Прошлый год
                    </div>
                    <div
                        onClick={() => toggleTab(1)}
                        className={
                            toggleState === 1
                                ? "documents-menu-item"
                                : "documents-menu-item-active"
                        }
                    >
                        Текущий год
                    </div>
                </div>
                <div className="table-section">
                    <div className="tableRowHeader">
                        {dataHeader &&
                            dataHeader.map((el, index) => (
                                <div key={index} className="tableRowHeader__header">
                                    {el}
                                </div>
                            ))}
                    </div>
                    <table className="table-a">
                        <tbody>
                            {leavesCurrent.items &&
                            leavesCurrent.items.map(
                                (el, i) => (
                                    // <div>
                                    <tr
                                        onClick={(e) =>
                                            clickHandlerAnnual(el, i, e)
                                        }
                                        key={i}
                                        className="table-a__item-all"
                                    >
                                        <td className="table-a__item"> {el}</td>
                                        <td className="table-a__item">
                                            {" "}
                                            {toggleState === 2
                                                ? 0
                                                : leavesCurrent.daysLeave[i]}
                                        </td>
                                        <td
                                            key={i}
                                            className="table-a__item-all__itemDay"
                                        >
                                            {leavesCurrent.leavePeriod[i] &&
                                                leavesCurrent.leavePeriod[
                                                    i
                                                ].map((e, i) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className={
                                                                e.leave
                                                                    ? e.plan
                                                                        ? "plan"
                                                                        : "noPlan"
                                                                    // "plan"
                                                                    : "noLeave"
                                                            }
                                                        >
                                                        </div>
                                                    );
                                                })}
                                        </td>
                                    </tr>
                                ),
                                // </div>
                            )}
                        </tbody>
                    </table>
                </div>
                {isTerminal
                    ? (
                        <AnnualCalendarForTerminal
                            firstDisplayedMonth={firstDisplayedMonth}
                            shown={modalAnnual}
                            onClose={closeHandlerAnnual}
                            type={leaveType}
                            year={toggleState === 1 ? LeavesService.getCurrentYear() : LeavesService.getCurrentYear() - 1}
                            mausePosition={modalPosition} />
                    )
                    : (
                        <Modal shown={modalAnnual} close={closeHandlerAnnual}>
                            <AnnualCalendar
                                type={leaveType}
                                year={
                                    toggleState === 1
                                        ? LeavesService.getCurrentYear()
                                        : LeavesService.getCurrentYear() - 1
                                }
                                disableOtherMonth
                            />
                        </Modal>
                    )}
            </div>
            <Footer />
        </>
    ) : (
        <>
            <Header />
            {/*// TODO инлайн не надо*/}
            <div
                className="current-loader"
                style={{
                    width: "1160px",
                    height: "417px",
                    margin: "auto",
                    textAlign: "center",
                    marginTop: "270px",
                }}
            >
                <ClipLoader color="#6DCDF6" />
            </div>
            <Footer />
        </>
    );
};

export default VacationHolidaysPage;
