import { getLatesUnepCertificateThunkAction } from "./getLatesUnepCertificate.thunk.action";

import $api from "../../../../../services/axiosService";
import { deleteUnepIssueAction } from "../deleteUnepIssue.action";

export const approveCertificateBySmsThunkAction = (code, issueId, props) => async (dispatch) => {
    console.log(props);
    try {
        props.setLoader(true);
        const response = await $api.post(`v1/c/certificates/issues/${issueId.issueId}/confirm/sms/${code}`);
        switch (response.status) {
        case 200:
            props.setPhoneToggle(false);
            props.setLoader(false);
            dispatch(getLatesUnepCertificateThunkAction());
            return props.setDescription("Сертификат УНЭП будет выпущен в течение минуты");
        default:
            return true;
        }
    } catch (e) {
        props.setLoader(false);

        // console.log('approveCertificateBySmsThunkActionERROR: ', e.response)
        if (e?.response?.status) {
            console.log("STATUS EERROR code");
            return props.setAuthNumber(e?.response?.data?.detail);
        }

        dispatch(deleteUnepIssueAction());

        return props.setDescription(e?.response?.data?.detail);
    }
};
