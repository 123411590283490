import RequestsHelper from "../../../frontend.services/RequestServices/Requests.helper";

import React from "react";
import { v4 as uuidv4 } from "uuid";

const NotificationWindowRequests = (
    {
        notificationWindowStatus,
        setNotificationWindowStatus,
        notificationWindowStyle,
        blockingMessages,
        pixels,
    },
) => {
    function closeNotification(e) {
        setNotificationWindowStatus(false);
    }

    // console.log({
    //     notificationWindowStatus,
    //     setNotificationWindowStatus,
    //     notificationWindowStyle,
    //     blockingMessages,
    // });
    return (
        notificationWindowStatus && !notificationWindowStatus.isSuccess ? (
            <div style={{ top: `${pixels}px` }}
                className={notificationWindowStyle ? "request-notification" : "request-notification-none"}>
                <div className="request-notification__content">
                    {
                        blockingMessages
                            ? (<React.Fragment key={uuidv4()}>
                                {
                                    notificationWindowStatus.details && RequestsHelper
                                        .getUniqueFromArray(notificationWindowStatus.details)
                                        .map(response => {
                                            return (
                                                <React.Fragment key={uuidv4()}>
                                                    <br />
                                                    <br />
                                                    <div style={
                                                        response.block === 1
                                                            ? { color: "black" }
                                                            : { color: "#EEEEEE" }
                                                    }>
                                                        {response.message}
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })
                                }
                            </React.Fragment>)
                            : (<React.Fragment key={uuidv4()}>
                                {
                                    notificationWindowStatus.details && notificationWindowStatus.details.map((e, i) => {
                                        return (<p key={uuidv4()}>{e.message}</p>);
                                    })
                                }
                            </React.Fragment>)
                    }

                </div>
                {/*// TODO PURE CSS CROSS*/}
                <div
                    onClick={closeNotification}
                    className="request-notification__cross"></div>
            </div>)
            : null

    );
};

export default NotificationWindowRequests;
