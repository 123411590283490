import $api from "../../../../services/axiosService";
import { getApplicationFileAction } from "../../Application/getApplicationFile.action";
// import {setCurrentRequestAction} from "../../CurrentRequest/setCurrentRequest.action";

import {
    MATERNITY_VALIDATE_URL,
    PARENTAL_LEAVE_VALIDATE_URL,
    STUDY_LEAVE_VALIDATE_URL,
} from "../../../../enums/Request/applicationUrls";
import { setValidatedApplicationAction } from "../../CurrentRequest/setValidatedApplication.action";

// import moment from "moment";

function applicationUrl(option) {
    switch (option) {
    case 5:
        return STUDY_LEAVE_VALIDATE_URL;
    case 6:
        return MATERNITY_VALIDATE_URL;
    case 7:
        return PARENTAL_LEAVE_VALIDATE_URL;
    default:
        return "v1/leaverequest/validate";
    }
}

export const requestValidationThunkAction = (
    {
        setRequestStatus,
        stateLoader,
        closeModal,
        currentRequest,
        setModalError,
        setUnblockingMessage,
    },
) =>
    async (dispatch) => {
        try {
            stateLoader(true);
            const temp = applicationUrl(currentRequest.leaveTypeId);
            const response = await $api.post(`${temp}`, currentRequest);
            if (response.data.isSuccess) {
                const currentRequest = await $api(`v1/leaverequest/${response.data.uid}`);
                if (currentRequest.data.item.attachments.length) {
                    for (let i = 0; i < currentRequest.data.item.attachments.length; i += 1) {
                        if (currentRequest.data.item.attachments[i].isApplicationDocument) {
                            const file = await $api(
                                `v1.1/leaverequest/file/
                                    ${response.data.uid}/
                                    ${currentRequest.data.item.attachments[i].id}`,
                                { responseType: "blob" });
                            const blobLink = URL.createObjectURL(
                                new Blob([file.data], { type: `${file.headers["content-type"]}` }),
                            );
                            dispatch(getApplicationFileAction(blobLink));
                        }
                    }
                    dispatch(setValidatedApplicationAction(response.data));
                    setRequestStatus(response.data);
                    if (!(response.data.isSuccess)) {
                        closeModal(false);
                    }
                    if (response.data.isSuccess && response?.data?.details && response?.data?.details.length) {
                        console.log("UnblockingMessage: ", response.data.details.filter(el => !el.block));
                        setUnblockingMessage(response.data.details.filter(el => !el.block));
                    }
                    stateLoader(false);
                }
            } else {
                if (!(response.data.isSuccess)) {
                    setRequestStatus(response.data);
                    closeModal(false);
                }
                if (response.data.isSuccess && response?.data?.details && response?.data?.details.length) {
                    console.log("UnblockingMessage: ", response.data.details.filter(el => !el.block));
                    setUnblockingMessage(response.data.details.filter(el => !el.block));
                }
            }
        } catch (error) {
            if (error && error.response && error.response.status === 500) {
                setModalError(prev => {
                    return {
                        ...prev, status: true, validation: true,
                    };
                });
                closeModal(false);
                stateLoader(false);
            }
            // else if (error.response.status === 400) {
            //     setRequestStatus({
            //         details: [
            //             {
            //                 message: error.response.data.detail,
            //                 block: 1,
            //             }],
            //     });
            //
            // }
            closeModal(false);
            stateLoader(false);
        }
    };
