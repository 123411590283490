import fileImg from "../../../assets/icons/file_blue.svg";
import deleteImage from "../../../assets/icons/delete_plus.svg";

import {
    newDeleteAttachmentThunkAction,
} from "../../../redux/actions/Requests/Attachments/thunk/newDeleteAttachment.thunk.action";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

import "./SubTypeRequestAttachmentsNew.scss";

const SubTypeRequestAttachmentsNew = ({
    deleteIcon,
    subTypeNumber,
    index,
    setSubDocumentsType,
    subDocumentsType,
    field,
    i,
}) => {
    const dispatch = useDispatch();

    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [attach] = useState(field ? currentRequest &&
        currentRequest[`${field}`] &&
        currentRequest[`${field}`][index] &&
        currentRequest[`${field}`][index].attachments : currentRequest && currentRequest.attachments);

    function deleteAttachment(element) {
        dispatch(
            newDeleteAttachmentThunkAction({
                attach: element,
                setDeleteStatus,
                personIndex: index,
                attachArray: `${field}`,
            }),
        );

    }

    useEffect(() => {
        if (field) {
            if (
                currentRequest &&
                currentRequest[`${field}`] &&
                currentRequest[`${field}`][index] &&
                currentRequest[`${field}`][index].attachments
            ) {
                console.log(currentRequest[`${field}`][index].attachments, subTypeNumber);
                const temp = currentRequest[`${field}`][index].attachments.filter(
                    (el) => el.type === subTypeNumber || el.subDocumentType === subTypeNumber,
                );
                setSubDocumentsType(temp);
                console.log(temp);
            }
        } else {
            if (attach && subTypeNumber) {
                const temp = currentRequest.attachments.filter(
                    (el) => (el.subDocumentType === subTypeNumber) || (el.type === subTypeNumber),
                );
                setSubDocumentsType(temp);
            } else {
                setSubDocumentsType(currentRequest && currentRequest.attachments);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRequest, attach, field]);
    return (attach || currentRequest?.attachments) ?
        (
            <div>
                {subDocumentsType &&
                    subDocumentsType.map((e, indexEl) => {
                        return (
                            <div className="custom-uploader__item" key={indexEl}>
                                <img
                                    className="custom-uploader__item__img"
                                    src={fileImg}
                                    alt="file"
                                />
                                <div
                                    style={{ position: "relative" }}
                                    className="custom-uploader__item__link"
                                >
                                    <a
                                        target="_blank"
                                        href={e.blobLink}
                                        className="custom-uploader__item__name"
                                        rel="noreferrer"
                                    >
                                        {e.fileName}
                                        <div className="custom-uploader__item__name__label">
                                            ( {e.fileSizeInMb})Мбайт
                                        </div>
                                    </a>
                                </div>
                                {deleteIcon ? null : deleteStatus ? (
                                    <ClipLoader color="#6DCDF6" size={32} />
                                ) : (
                                    <div className="custom-uploader__item__delete">
                                        <img
                                            id={e.id}
                                            onClick={() => deleteAttachment(e)}
                                            src={deleteImage}
                                            alt="delete"
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
        ) : (
            <ClipLoader color="#6DCDF6" />
        );


    //   return field ? (currentRequest &&
    // currentRequest[`${field}`] &&
    // currentRequest[`${field}`][index] &&
    // currentRequest[`${field}`].attachments ? (
    //           <div>
    //               {subDocumentsType &&
    //       subDocumentsType.map((e, indexEl) => {
    //           return (
    //               <div className="custom-uploader__item" key={e.id}>
    //                   <img
    //                       className="custom-uploader__item__img"
    //                       src={fileImg}
    //                       alt="file"
    //                   />
    //                   <div
    //                       style={{ position: "relative" }}
    //                       className="custom-uploader__item__link"
    //                   >
    //                       <a
    //                           target="_blank"
    //                           href={e.blobLink}
    //                           className="custom-uploader__item__name"
    //                           rel="noreferrer"
    //                       >
    //                           {e.fileName}
    //                           <div className="custom-uploader__item__name__label">
    //                   ( {e.fileSizeInMb})Мбайт
    //                           </div>
    //                       </a>
    //                   </div>
    //                   {deleteIcon ? null : deleteStatus ? (
    //                       <ClipLoader color="#6DCDF6" size={32} />
    //                   ) : (
    //                       <div className="custom-uploader__item__delete">
    //                           <img
    //                               id={e.id}
    //                               onClick={() => deleteAttachment(e)}
    //                               src={deleteImage}
    //                               alt="delete"
    //                           />
    //                       </div>
    //                   )}
    //               </div>
    //           );
    //       })}
    //           </div>
    //       ) : (
    //           <ClipLoader color="#6DCDF6" />
    //       )) : (
    //       currentRequest && currentRequest.attachments ? (
    //           <div>
    //               {subDocumentsType &&
    //         subDocumentsType.map((e, indexEl) => {
    //             return (
    //                 <div className="custom-uploader__item" key={e.id}>
    //                     <img
    //                         className="custom-uploader__item__img"
    //                         src={fileImg}
    //                         alt="file"
    //                     />
    //                     <div
    //                         style={{ position: "relative" }}
    //                         className="custom-uploader__item__link"
    //                     >
    //                         <a
    //                             target="_blank"
    //                             href={e.blobLink}
    //                             className="custom-uploader__item__name"
    //                             rel="noreferrer"
    //                         >
    //                             {e.fileName}
    //                             <div className="custom-uploader__item__name__label">
    //                     ( {e.fileSizeInMb})Мбайт
    //                             </div>
    //                         </a>
    //                     </div>
    //                     {deleteIcon ? null : deleteStatus ? (
    //                         <ClipLoader color="#6DCDF6" size={32} />
    //                     ) : (
    //                         <div className="custom-uploader__item__delete">
    //                             <img
    //                                 id={e.id}
    //                                 onClick={() => deleteAttachment(e)}
    //                                 src={deleteImage}
    //                                 alt="delete"
    //                             />
    //                         </div>
    //                     )}
    //                 </div>
    //             );
    //         })}
    //           </div>
    //       ) : (
    //           <ClipLoader color="#6DCDF6" />
    //       )
    //
    //   );
};
export default SubTypeRequestAttachmentsNew;
