import { $api } from "../../../../services/request.config";
import { getOrderOfChildAction } from "../getOrderOfChild.action";

export const getOrderOfChildThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/requests/leaves/parental/children/orders");
        dispatch(getOrderOfChildAction(response.data));

    } catch (error) {
        // throw new Error(error.response);
    }
};
