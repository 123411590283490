import StudentInformation from "./StudentInformation/StudentInformation";

import DropdownMenu from "../../../../../common/Menu/DropdownMenu/DropdownMenu";
import CommentInput from "../../../../../common/Inputs/CommentInput/CommentInput";

import NotificationWindowRequests from "../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import SinglePairDatesForUnpaidPractise
    from "../../../../../common/Inputs/SinglePairDatesForUnpaidPractise/SinglePairDatesForUnpaidPractise";
import RequestInput from "../../../../../common/Inputs/RequestInput/RequestInput";

import {
    getInternhipsTypeThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/UnpainPractice/thunk/getInternhipsType.thunk.action";
import {
    getInternshipsStructsThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/UnpainPractice/thunk/getInternshipsStructs.thunk.action";
import {
    getInternshipsStructsEmployeesThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/UnpainPractice/thunk/getInternshipsStructsEmployees.thunk.action";
import { clearAttachmentsAction } from "../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import {
    emptyCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import {
    dropdownSelectionUnpaidPractice,
} from "../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelectionUnpaidPractice";
import {
    addBasicInputHandlerCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/addBasicInputHandler.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";

import UnpaidPracticeApplication from "../../../../../../frontend.classes/Requests/UnpaidPractice.application";
import {
    requestValidationForLeaderRequestsThunkAction,
} from "../../../../../../redux/actions/Requests/RequestValidation/thunk/requestValidationForLeaderRequests.thunk.action";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import RequestButtons from "../../../../../common/Button/RequestButtons/RequestButtons";
import EmptyBody from "../../../../../common/EmptyBody/EmptyBody";
import { Modal } from "../../../../../common/Modal/Modal";
import BackHandlerModal from "../../../../../common/Modal/ModalConditions/Requests/BackHandlerModal/BackHandlerModal";
import SaveSuccessModal from "../../../../../common/Modal/ModalConditions/Requests/SaveSuccessModal/SaveSuccessModal";
import ModalBanOverlay from "../../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import RequestVerification
    from "../../../../../common/Modal/ModalConditions/Requests/RequestVerification/RequestVerification";
import Error500Modal from "../../../../../common/Modal/ModalConditions/Requests/Error500Modal/Error500Modal";
import {
    saveDraftCurrentLeaderRequestThunk,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/thunk/saveDraftCurrentLeaderRequest.thunk.action";
import DropdownMenuTest from "../../../../../common/Menu/DropdownMenuTest/DropdownMenuTest";
import {
    updateCurrentLeaderRequestThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/thunk/updateCurrentLeaderRequest.thunk.action";


import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import "./UnpaidPractice.scss";


function UnpaidPractice() {
    const [notificationWindow, setNotificationWindow] = useState(false);

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const internshipsTypes = useSelector(
        (state) => state.requests.internshipsTypes,
    );
    const internshipsStructs = useSelector(
        (state) => state.requests.internshipsStructs,
    );
    const internshipsEmployees = useSelector(
        (state) => state.requests.internshipsEmployees,
    );


    const [sendButtonLoading, setSendButtonLoading] = useState(false);
    const [onValidation, setOnValidation] = useState(false);

    // const [typesListLoader, setTypesListLoader] = useState(false);
    //
    // const [, setRequestOption] = useState(null);

    // NOTIFICATION IN CONTROL PROCEDURES CONTENT
    const [requestStatus, setRequestStatus] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [modalSuccessStatus, setModalSuccessStatus] = useState(false);
    const [modalSentStatus, setModalSentStatus] = useState(false);
    const [modalError, setModalError] = useState({
        sign: false,
        validation: false,
        status: false,
        saving: false,
    });

    const [isRunningSaveHandler, setIsRunningSaveHandler] = useState(false);
    const [isRunningSendHandler, setIsRunningSendHandler] = useState(false);


    function backHandler(e) {
        currentRequest && currentRequest.leaveReasonId
            ? setModalStatus(true)
            : navigate("/main/hr-services/leader-requests");
    }

    async function saveHandler(e) {
        let result = false;
        const temp = await UnpaidPracticeApplication.filterStudents(currentRequest);
        if (UnpaidPracticeApplication.saveOrUploadValidation(currentRequest, dispatch)) {
            // setModalSuccessStatus(true);
            setIsRunningSaveHandler(true);
            if (!currentRequest.uid) {
                result = dispatch(
                    saveDraftCurrentLeaderRequestThunk(
                        temp,
                        setModalSuccessStatus,
                        params.id,
                        null,
                        null,
                        setModalError,
                    ),
                );
            } else {
                result = dispatch(
                    updateCurrentLeaderRequestThunkAction(
                        temp,
                        setModalSuccessStatus,
                        params.id,
                        setModalError,
                    ),
                );
            }
        }
        if (result)
            result.then(() => {
                setIsRunningSaveHandler(false);
            });
        else setIsRunningSaveHandler(false);
    }

    function sendHandler(e) {
        let result = false;
        if (UnpaidPracticeApplication.sendToValidation(currentRequest, dispatch)) {
            setModalSentStatus(true);
            setIsRunningSendHandler(true);
            result = dispatch(
                requestValidationForLeaderRequestsThunkAction({
                    currentRequest,
                    setRequestStatus,
                    stateLoader: setSendButtonLoading,
                    closeModal: setModalSentStatus,
                    setModalError,
                    option: Number(params.id),
                }),
            );
        }
        if (result)
            result.then(() => {
                setIsRunningSendHandler(false);
            });
        else setIsRunningSendHandler(false);
    }


    function commentHandler(event) {
        dispatch(addCommentHandlerCurrentRequest(event.target.value));
    }

    function inputHandler(e, field) {
        let value = e.target.value;
        if (field === "courseNumber") {
            value = Number(e.target.value.replace(/[a-z0,.789]/i, ""));
        } else if (field === "contactPhone") {
            value = e.target.value.replace(/[a-z]/i, "");
        }
        dispatch(
            addBasicInputHandlerCurrentRequest({
                field,
                value,
            }),
        );
        dispatch(validationControlCurrentRequest(field, true));
    }

    function selectDropdown(event, element, stateProperty) {
        dispatch(validationControlCurrentRequest("stateProperty", false));

        if (stateProperty === "structId") {
            dispatch(getInternshipsStructsEmployeesThunkAction(element.id));
        }
        dispatch(
            dropdownSelectionUnpaidPractice({
                event,
                elem: element,
                stateNameUnpaid: stateProperty,
            }),
        );
    }

    useEffect(() => {
        setRequestStatus(false);
        dispatch(clearAttachmentsAction());
        dispatch(getInternhipsTypeThunkAction());
        dispatch(getInternshipsStructsThunkAction());
        if (!queryId) {
            dispatch(
                emptyCurrentRequest(
                    new UnpaidPracticeApplication({
                        leaveTypeId: 15,
                        students: [
                            {
                                fullName: "",
                                isValid: true,
                                attachments: [],
                            },
                        ],
                    }),
                ),
            );
            setRequestStatus((prev) => ({
                details: [
                    {
                        block: 1,
                        message:
                        // eslint-disable-next-line max-len
                            "Обращаем внимание:до запуска заявки студент должен успешно пройти проверку службы безопасности Компании",
                    },
                ],
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);

        }
    }, [requestStatus]);


    return (
        <section className={"unpaid-practice"}>
            <DropdownMenu
                helper={
                    // eslint-disable-next-line max-len
                    "Обращаем внимание: в одной заявке можно оформить только студентов с одним типом практики. В случае отличия необходимо оформить отдельную заявку"
                }
                currentRequest={currentRequest}
                styleName={"reason"}
                label={"Тип практики *"}
                listOfOptions={internshipsTypes}
                stateProperty={"internshipTypeId"}
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.internshipTypeId
                        ? currentRequest.isValid.internshipTypeId
                        : false
                }
                selectOptionClick={selectDropdown}
            />
            <SinglePairDatesForUnpaidPractise
                labelFrom={"Дата начала практики *"}
                labelTo={"Дата окончания практики *"}
            />

            <RequestInput
                value={currentRequest && currentRequest.university}
                basicInput={true}
                maxLength={300}
                stateProperty={"university"}
                changeHandler={(e) => inputHandler(e, "university")}
                label={"Полное наименование ВУЗа *"}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.university
                        ? ""
                        : "Обязательное поле"
                }
                letter={300}
                parentStyle={"unpaid-practice"}
            />
            <RequestInput
                value={currentRequest && currentRequest.faculty}
                basicInput={true}
                maxLength={300}
                stateProperty={"faculty"}
                changeHandler={(e) => inputHandler(e, "faculty")}
                label={"Наименование факультета *"}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.faculty
                        ? ""
                        : "Обязательное поле"
                }
                letter={300}
                parentStyle={"unpaid-practice"}
            />
            <RequestInput
                value={currentRequest && currentRequest.chair}
                basicInput={true}
                maxLength={300}
                stateProperty={"chair"}
                changeHandler={(e) => inputHandler(e, "chair")}
                label={"Наименование кафедры *"}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.chair
                        ? ""
                        : "Обязательное поле"
                }
                parentStyle={"unpaid-practice"}
                letter={300}
            />
            <CommentInput
                value={currentRequest && currentRequest.courseNumber}
                basicInput={true}
                maxLength={1}
                stateProperty={"courseNumber"}
                changeHandler={(e) => inputHandler(e, "courseNumber")}
                label={"Номер курса *"}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.courseNumber
                        ? ""
                        : "Обязательное поле"
                }
                parentStyle={"unpaid-practice"}
            />
            <RequestInput
                value={currentRequest && currentRequest.contactPerson}
                basicInput={true}
                maxLength={300}
                stateProperty={"contactPerson"}
                changeHandler={(e) => inputHandler(e, "contactPerson")}
                label={"ФИО контактного лица в ВУЗе по организации практики *"}
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.contactPerson
                        ? ""
                        : "Обязательное поле"
                }
                parentStyle={"unpaid-practice"}
                letter={300}
            />
            <CommentInput
                value={currentRequest && currentRequest.contactPhone}
                basicInput={true}
                maxLength={12}
                helper={"7 *** *** ** **"}
                stateProperty={"contactPhone"}
                changeHandler={(e) => inputHandler(e, "contactPhone")}
                label={
                    "Телефон контактного лица в ВУЗе по организации практики *"
                }
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.contactPhone
                        ? ""
                        : "Обязательное поле"
                }
                parentStyle={"unpaid-practice"}
            />
            <CommentInput
                value={currentRequest && currentRequest.contactEmail}
                basicInput={true}
                maxLength={255}
                stateProperty={"contactEmail"}
                changeHandler={(e) => inputHandler(e, "contactEmail")}
                label={
                    "E-mail контактного лица в ВУЗе по организации практики *"
                }
                errorMessage={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.contactEmail
                        ? ""
                        : "Обязательное поле"
                }
                parentStyle={"unpaid-practice"}
            />
            <DropdownMenuTest
                currentRequest={currentRequest}
                styleName={"reason"}
                label={"Подразделение, в котором будет проходить практика *"}
                listOfOptions={internshipsStructs}
                stateProperty={"structId"}
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.structId
                        ? currentRequest.isValid.structId
                        : false
                }
                selectOptionClick={selectDropdown}
            />
            {currentRequest &&
            currentRequest.structId &&
            currentRequest.dateFrom ? (
                    <DropdownMenu
                        helper={
                            "Обращаем внимание: руководитель практики в ее период не должен быть в отпуске или в другой неявке"
                        }
                        currentRequest={currentRequest}
                        styleName={"reason"}
                        label={"Руководитель практики в подразделении *"}
                        listOfOptions={internshipsEmployees}
                        stateProperty={"headPid"}
                        isValidKey={
                            currentRequest &&
                        currentRequest.isValid &&
                        currentRequest.isValid.headPid
                                ? currentRequest.isValid.headPid
                                : false
                        }
                        selectOptionClick={selectDropdown}
                    />
                ) : (
                    <div style={{ minHeight: "40px" }}>{""}</div>
                )}

            <DropdownMenu
                helper={
                    "Обращаем внимание: руководитель практики должен обеспечить рабочим местом студента"
                }
                currentRequest={currentRequest}
                styleName={"reason"}
                label={
                    "В рамках прохождения практики потребуется рабочее место с компьютером *"
                }
                listOfOptions={[
                    {
                        name: "Нет, не требуется",
                        id: false,
                    },
                    {
                        name: "Да, требуется",
                        id: true,
                    },
                ]}
                stateProperty={"workplacePreparationNeeded"}
                isValidKey={
                    currentRequest &&
                    currentRequest.isValid &&
                    currentRequest.isValid.workplacePreparationNeeded
                        ? currentRequest.isValid.workplacePreparationNeeded
                        : false
                }
                selectOptionClick={selectDropdown}
            />
            {currentRequest &&
                currentRequest.students &&
                currentRequest.students.map((student, index) => {
                    return (
                        <StudentInformation
                            parentStyle={"unpaid-practice"}
                            student={student}
                            index={index}
                            key={index}
                            studentsLength={currentRequest.students.length}
                        />
                    );
                })}
            <CommentInput
                maxLength={255}
                value={currentRequest && currentRequest.employeeComment}
                changeHandler={commentHandler}
                label={"Комментарий "}
                parentStyle={"extra-vacation-days"}
            />
            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
                blockingMessages={true}
            />
            {Number(params.id) === 15 ? (
                <RequestButtons
                    sendHandler={sendHandler}
                    saveHandler={saveHandler}
                    backHandler={backHandler}
                    isRunningSaveHandler={isRunningSaveHandler}
                    isRunningSendHandler={isRunningSendHandler}
                />
            ) : (
                <EmptyBody backHandler={backHandler} />
            )}

            {/*BACK*/}
            <Modal shown={modalStatus} close={setModalStatus}>
                <BackHandlerModal
                    handler={saveHandler}
                    close={setModalStatus}
                />
            </Modal>
            {/*SAVE SUCCESS*/}
            <Modal shown={modalSuccessStatus} close={setModalSuccessStatus}>
                <SaveSuccessModal
                    route={"/main/hr-services/leader-requests"}
                    close={setModalSuccessStatus}
                />
            </Modal>
            {/*REQUEST SEND*/}
            <ModalBanOverlay shown={modalSentStatus} close={setModalSentStatus}>
                <RequestVerification
                    onValidation={onValidation}
                    setOnValidation={setOnValidation}
                    setModalError={setModalError}
                    sendButtonLoading={sendButtonLoading}
                    route={"/main/hr-services/"}
                    setModalSentStatus={setModalSentStatus}

                />
            </ModalBanOverlay>
            {modalError.status && (
                <ModalBanOverlay shown={modalError} close={setModalError}>
                    <Error500Modal
                        modalError={modalError}
                        setModalError={setModalError}
                    />
                </ModalBanOverlay>
            )}
        </section>
    );
}

export default UnpaidPractice;
