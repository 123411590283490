import { UNSET_USER } from "../../../enums/AuthType";
import { UNSET_PERSONAL_DATA } from "../../../enums/Common/Common";

export const userLogOutAction = () => {
    return {
        type: UNSET_USER,
    };
};
export const unsetPersonalData = () => {
    return {
        type: UNSET_PERSONAL_DATA,
    };
};
