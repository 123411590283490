import NavMenuDocuments from "../../common/Documents/NavMenuDocuments/NavMenuDocuments";
import EmploymentDocuments from "../../common/Documents/EmploymentDocuments/EmploymentDocuments";
import References from "../../common/Documents/References/References";
import Approvals from "../../common/Documents/Approvals/Approvals";
import Applications from "../../common/Documents/Applications/Applications";
import {
    getUserRequestsThunkAction,
} from "../../../redux/actions/Requests/GetUserRequests/thunk/getUserRequests.thunk.action";

import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import {
    getUserHrDocsThunkAction,
} from "../../../redux/actions/Requests/Hr-documents/thunk/getUserHrDocs.thunk.action";
import {
    getLeaderRequestsThunkAction,
} from "../../../redux/actions/Requests/LeaderRequests/thunk/getLeaderRequests.thunk.action";
import {
    getAllUserReferencesThunkAction,
} from "../../../redux/actions/Requests/References/thunk/getAllUserReferences.thunk.action";
import ModalBanOverlay from "../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import ErrorModal from "../../common/Modal/ModalConditions/Profile/ErrorModal/ErrorModal";
import { Modal } from "../../common/Modal/Modal";
import WorkerSignType from "../../common/Modal/ModalConditions/UserSignType/WorkerSignType/WorkerSignType";
import { ScrollButton } from "../../common/componentsTerminal/ScrollButton/ScrollButton";
import {
    getStructureTopThunkAction,
} from "../../../redux/actions/Requests/LeaderRequests/thunk/getStructureTop.thunk.action";

import {
    getLatesUnepCertificateThunkAction,
} from "../../../redux/actions/User/profile/ElectronicSignature/thunk/getLatesUnepCertificate.thunk.action";
import { isTerminal } from "../../../constants";

import CustomLoader from "../../common/CustomLoader/CustomLoader";

import { getLeaderRequestsAction } from "../../../redux/actions/Requests/LeaderRequests/getLeaderRequests.action";

import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";

import { Outlet } from "react-router-dom";

isTerminal ? import ("./DocumentsTerminal.scss") : import ("./Documents.css");

const Documents = () => {
    const dispatch = useDispatch();
    const requests = useSelector((state) => state.requests);
    const isSubstitute = useSelector((state) => state.documents.isSubstitute);
    const user = useSelector(state => state.user);
    const [toggleState, setToggleState] = useState(
        localStorage.getItem("hasActualDocuments") === "true" ? 2 : 4,
    );
    const [currentRows, setCurrentRows] = useState(5);
    const [errorModal, setErrorModal] = useState(false);
    const [errDescription, setErrDescription] = useState("");
    const [signType, setSignType] = useState(false);
    const [refresh, setRefresh] = useState(true);

    const toggleTab = (index) => {
        setToggleState(index);
    };


    const currentRowsHandler = (id) => {
        setCurrentRows(id);
    };


    function closeSignType() {
        setSignType(false);
    }

    useEffect(() => {
        dispatch(
            getLeaderRequestsThunkAction({
                // setErrorModal:setErrorModal,
                // setErrDescription:setErrDescription
            }),
        );
        return () => {
            dispatch(getLeaderRequestsAction([]));
        };

    }, []);

    useEffect(() => {
        dispatch(
            getLatesUnepCertificateThunkAction({
                setErrorModal: setErrorModal,
                setErrDescription: setErrDescription,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    useEffect(() => {
        switch (toggleState) {
        case 2: // EmploymentDocuments requests.hrDocuments
            (!requests.hrDocuments || refresh) &&
                dispatch(
                    getUserHrDocsThunkAction({
                        setErrDescription: setErrDescription,
                        setErrorModal: setErrorModal,
                    }),
                );
            break;

        case 3: // References requests.userReferences
            (!requests.userReferences || refresh) &&
                dispatch(
                    getAllUserReferencesThunkAction({
                        // setErrorModal:setErrorModal,
                        // setErrDescription:setErrDescription
                    }),
                );
            break;

        case 4: // Applications requests.userRequests
            (!requests.userRequests || refresh) &&
                dispatch(getStructureTopThunkAction());
            dispatch(
                getUserRequestsThunkAction({
                    setErrorModal: setErrorModal,
                    setErrDescription: setErrDescription,
                }),
            );
            break;


        case 5: // Approvals requests.leaderRequests
            (!requests.leaderRequests || refresh) &&
                dispatch(getStructureTopThunkAction());
            dispatch(
                getLeaderRequestsThunkAction({
                    setErrorModal: setErrorModal,
                    setErrDescription: setErrDescription,
                }),
            );
            break;

        default:
            break;

        }

    }, [toggleState, user]);

    return (
        <>
            <Header />
            <div className="docs-main-container">
                <NavMenuDocuments
                    requests={requests}
                    toggleState={toggleState}
                    toggleTab={toggleTab}
                />
                <div className="content-tabs">
                    <div className={toggleState === 2 ? "active-content" : "content"}>
                        {
                            requests?.hrDocuments
                                ? <EmploymentDocuments
                                    hrDocuments={requests?.hrDocuments}
                                    currentRows={currentRows}
                                    currentRowsHandler={currentRowsHandler}
                                    rowsPerPage={isTerminal ? 8 : currentRows}
                                />
                                : <CustomLoader />
                        }
                    </div>

                    <div className={toggleState === 3 ? "active-content" : "content"}>
                        {
                            requests?.userReferences
                                ? <References
                                    currentRows={currentRows}
                                    currentRowsHandler={currentRowsHandler}
                                    rowsPerPage={isTerminal ? 8 : currentRows}
                                    userReferences={requests?.userReferences}
                                />
                                : <CustomLoader />
                        }
                    </div>

                    <div className={toggleState === 4 ? "active-content" : "content"}>
                        {
                            requests?.userRequests
                                ? <Applications
                                    userRequests={requests?.userRequests}
                                    currentRows={currentRows}
                                    currentRowsHandler={currentRowsHandler}
                                    rowsPerPage={isTerminal ? 8 : currentRows}
                                />
                                : <CustomLoader />
                        }
                    </div>

                    <div className={toggleState === 5 ? "active-content" : "content"}>
                        {
                            requests?.leaderRequests && requests.leaderRequests.length ? (
                                <Approvals
                                    requests={
                                        requests.leaderRequests
                                            ? requests.leaderRequests
                                            : null
                                    }
                                    currentRows={currentRows}
                                    currentRowsHandler={currentRowsHandler}
                                    rowsPerPage={currentRows}
                                />
                            ) : null}
                    </div>

                    <Outlet />
                </div>
            </div>

            <Footer />
            <ModalBanOverlay shown={errorModal} close={setErrorModal}>
                <ErrorModal
                    description={errDescription}
                    setError={setErrorModal}
                    route={"/documents"}
                    signType={true}
                />
            </ModalBanOverlay>
            <Modal shown={signType} close={closeSignType}>
                <WorkerSignType
                    description={errDescription}
                    setErrorModal={setErrorModal}
                    close={closeSignType}
                    signType={true}
                />
            </Modal>
        </>
    );
};

export default React.memo(Documents);
