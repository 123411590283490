import { getGenderForChildAction } from "../getGenderForChild.action";
import { $api } from "../../../../services/request.config";

export const getGenderForChildThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/requests/leaves/parental/children/genders");
        dispatch(getGenderForChildAction(response.data));

    } catch (error) {
        // throw new Error(error.response);
    }
};
