import triangle from "../../../../assets/documents/triangle.svg";

export const approvalsData = [
    {
        id: "head-1",
        title: "тип",
        icon: triangle,
        width: "98px",
        // shapes:[{
        //
        // },fdg]
    },
    {
        id: "head-2",
        title: "наименование",
        icon: "",
        width: "355px",
    },
    {
        id: "head-3",
        title: "согласовать до",
        icon: "",
        width: "160px",
    },
    {
        id: "head-4",
        title: "статус",
        icon: "",
        width: "177px",
    },
    {
        id: "head-5",
        title: "комментарий",
        icon: "",
        width: "161px",
    },
    {
        id: "head-6",
        title: "Дата",
        icon: "",
        width: "204px",
    },
];
