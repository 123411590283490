import UButton from "../../../Button/UButton/UButton";
import {
    deleteCurrentRequestThunkAction,
} from "../../../../../redux/actions/Requests/CurrentRequest/thunk/deleteCurrentRequest.thunk.action";
import SwitchRequests from "../../../SwitchRequests/SwitchRequests";
import DeleteRequest from "../../../Modal/ModalConditions/Requests/DeleteRequest/DeleteRequest";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";
import { isTerminal } from "../../../../../constants";

import {
    getStructureTopThunkAction,
} from "../../../../../redux/actions/Requests/LeaderRequests/thunk/getStructureTop.thunk.action";
import { ScrollButton } from "../../../componentsTerminal/ScrollButton/ScrollButton";

import { clearEmployeeListAction } from "../../../../../redux/actions/MyDivision/EmployeeList/getEmployeeList.action";

import {
    unsetCurrentDocumentAction,
} from "../../../../../redux/actions/Requests/CurrentRequest/setCurrentDocument.action";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";

import { element } from "prop-types";

isTerminal ? import("./CurrentRequestCardTerminal.scss") : import("./CurrentRequestCard.scss");

const CurrentRequestCard = (props) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const currentRequest = useSelector((state) => state.requests.singleDocument);
    const structTop = useSelector(state => state.requests.structTop);

    const [assetName, setAssetName] = useState("");
    const [loader, setLoader] = useState(false);
    const [deleteRequest, setDeleteRequest] = useState(null);
    // const [attachmentLoader, setAttachmentLoader] = useState(false);
    const [reject, setReject] = useState(null);
    const [input, setInput] = useState("");

    // console.log(input)

    function deleteHandler(action, num, uid, reason = false) {
        if (action === "reject" || action === "approve") {
            const pid = props.userRequests.find((el) => el.uid === uid).pid;
            if (pid) {
                dispatch(
                    deleteCurrentRequestThunkAction(
                        action,
                        num,
                        uid,
                        {
                            setState: setLoader,
                            setDeleteState: setDeleteRequest,
                        },
                        reason,
                        pid,
                    ),
                );
            }
        } else {
            dispatch(
                deleteCurrentRequestThunkAction(
                    action,
                    num,
                    uid,
                    {
                        setState: setLoader,
                        setDeleteState: setDeleteRequest,
                    },
                    reason,
                ),
            );
        }
    }

    function skipRejectHandler() {
        setReject(false);
        props.close();
    }

    function goToRefactor(currentRequest) {
        const { uid, leaveTypeId } = currentRequest;
        if (leaveTypeId === 12 || leaveTypeId === 14) {
            navigate(`/main/hr-services/layoff/request?id=${uid}`);
        } else if (leaveTypeId === 15) {
            navigate(`/main/hr-services/leader-requests/15?id=${uid}`);
        } else if (leaveTypeId === 16) {
            navigate(`/main/hr-services/leader-requests/16?id=${uid}`);
        } else {
            navigate(`/main/hr-services/work-time/requests?id=${uid}`);
        }
    }

    useEffect(() => {
        if (currentRequest && currentRequest.currentRequest && currentRequest.currentRequest.leaveTypeId === 16) {
            setAssetName(structTop && structTop.find((element) => element.id === currentRequest.currentRequest.assetId).name);

            // dispatch(getStructureTopThunkAction());
        }
    }, [currentRequest && currentRequest.currentRequest]);

    useEffect(() => {
        return () => {
            dispatch(unsetCurrentDocumentAction());
        };
    }, []);
    // TODO REFACTOR MARKUP!!!
    return !currentRequest
        ? (<div className="request-modal-loader">
            <ClipLoader color="#6DCDF6" />
        </div>)
        : (<div className="request-modal">
            {isTerminal && <ScrollButton propsClassName={".request-modal__table"} />}
            {
                !loader
                    ? (deleteRequest
                        ? (<DeleteRequest
                            close={props.close}
                            deleteRequest={deleteRequest}
                        />)
                        : (<>
                            <SwitchRequests
                                setDescription={props.setDescription}


                                leaveReasonTypeId={
                                    currentRequest.currentRequestCard[
                                        "Наименование"
                                    ]
                                }
                                isLeader={props.isLeader}
                                isReferense={props.isReferense}
                                userRequests={props.userRequests}
                                currentRequest={
                                    props.isReferense
                                        ? currentRequest
                                        : currentRequest.currentRequest
                                }
                                reject={reject}
                                typeText={
                                    props.isReferense
                                        ? currentRequest["Наименование"]
                                        : "Заявка"
                                }
                                number={
                                    props.isReferense
                                        ? currentRequest["Номер заявки"]
                                        : currentRequest.currentRequest.orderNumber
                                }
                            />
                            {/*<table >*/}
                            <div className="request-modal__table">
                                {
                                    reject
                                        ? (<div className="request-modal__table__line reject">
                                            <div className="left-column">
                                                    укажите причину отклонения:
                                            </div>
                                            <div className="right-column">
                                                <input
                                                    autoFocus
                                                    placeholder={"Введите текст"}
                                                    name="reason"
                                                    className="right-column__input"
                                                    maxLength={255}
                                                    onChange={(event) =>
                                                        setInput(
                                                            (prev) =>
                                                                event.target.value,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>)
                                        : !props.isReferense
                                            ? (<>
                                                {
                                                    currentRequest &&
                                                        Object.keys(currentRequest.currentRequestCard)
                                                            .map((el, index) => {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className="request-modal__table__line"
                                                                    >
                                                                        <div className="left-column">
                                                                            {el}
                                                                        </div>
                                                                        <div className="right-column">
                                                                            {
                                                                                currentRequest
                                                                                    .currentRequestCard[
                                                                                        el
                                                                                    ]
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                }
                                                {
                                                    currentRequest.currentRequest.leaveTypeId === 16 &&
                                                        <>
                                                            <div className="request-modal__table__line">
                                                                <div className="left-column">
                                                                    Описание задания
                                                                </div>
                                                                <div className="right-column">
                                                                    {currentRequest.currentRequest.description}
                                                                </div>
                                                            </div>
                                                            <div className="request-modal__table__line">
                                                                <div className="left-column">
                                                                    Актив
                                                                </div>
                                                                <div className="right-column">
                                                                    {assetName}
                                                                </div>
                                                            </div>
                                                            <div className="request-modal__table__line">
                                                                <div className="left-column">
                                                                    Цель задания
                                                                </div>
                                                                <div className="right-column">
                                                                    {currentRequest.currentRequest.goal}
                                                                </div>
                                                            </div>
                                                            <div className="request-modal__table__line">
                                                                <div className="left-column">
                                                                    ФИО/должность/сумма
                                                                </div>
                                                                <div className="right-column">
                                                                    {
                                                                        currentRequest.currentRequest.workers && currentRequest.currentRequest.workers.map((element, index) => {
                                                                            return (
                                                                                <div className={"approvers"}>
                                                                                    <span
                                                                                        className={"approvers__normal"}>
                                                                                        {element.lastName} {element.firstName[0]}.{element.secondName[0]}.
                                                                                    </span>
                                                                                    <span className={"approvers__normal"}>
                                                                                        {element.jobTitle}
                                                                                    </span>
                                                                                    <span className={"approvers__normal"}>
                                                                                        {element.amount} руб.
                                                                                    </span>

                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="request-modal__table__line">
                                                                <div className="left-column">
                                                                    ИТОГ СУММЫ ПРЕМИИ
                                                                </div>
                                                                <div className="right-column">
                                                                    {currentRequest.currentRequest.workers && currentRequest.currentRequest.workers.reduce((acc, element) => {
                                                                        return acc += element.amount;
                                                                    }, 0)} руб.
                                                                </div>
                                                            </div>
                                                        </>
                                                }

                                                {
                                                    currentRequest.currentRequest.approvers &&
                                                        currentRequest.currentRequest.approvers.length !== 0 &&
                                                        (<div className="request-modal__table__line">
                                                            <div className="left-column">
                                                                ФИО согласующего/
                                                                <br />
                                                                дата поступления
                                                            </div>
                                                            <div className="right-column">
                                                                {
                                                                    currentRequest.currentRequest.approvers.length &&
                                                                    currentRequest.currentRequest.approvers
                                                                        .map((el, i) => {
                                                                            return (<div
                                                                                className={
                                                                                    "approvers"
                                                                                }
                                                                                key={i}>
                                                                                <span
                                                                                    className={
                                                                                        // eslint-disable-next-line max-len
                                                                                        currentRequest.currentRequest.status >= 6 ? "approvers__passive" :
                                                                                            (currentRequest.currentRequest.approvers[i + 1]
                                                                                                ? currentRequest
                                                                                                    .currentRequest
                                                                                                    .approvers[i + 1].assignmentDate
                                                                                                    ? "approvers__passive"
                                                                                                    : "approvers__active"
                                                                                                : el.assignmentDate
                                                                                                    ? "approvers__active"
                                                                                                    : "approvers__normal")
                                                                                    }>
                                                                                    {el.lastName}{" "}
                                                                                    {el.firstName[0]}.
                                                                                    {el.secondName[0]}.
                                                                                </span>
                                                                                <span
                                                                                    className={
                                                                                        currentRequest.currentRequest.status >= 6 ? "approvers__passive" :

                                                                                            (currentRequest.currentRequest.approvers[i + 1]
                                                                                                ? currentRequest
                                                                                                    .currentRequest.approvers[i + 1]
                                                                                                    .assignmentDate
                                                                                                    ? "approvers__passive"
                                                                                                    : "approvers__active"
                                                                                                : el.assignmentDate
                                                                                                    ? "approvers__active"
                                                                                                    : "approvers__normal")
                                                                                    }>
                                                                                    {el.statusText}
                                                                                </span>
                                                                                {el.statusNote &&
                                                                                    (<span
                                                                                        className={"approvers__comment"}>
                                                                                        {el.statusNote}
                                                                                    </span>
                                                                                    )}
                                                                            </div>);
                                                                        })
                                                                }
                                                            </div>
                                                        </div>)
                                                }
                                                {
                                                    currentRequest.currentRequest.attachments.length !== 0 &&
                                                        (<div className="request-modal__table__line">
                                                            <div className="left-column">
                                                                документы
                                                            </div>
                                                            <div className="right-column">
                                                                {
                                                                    currentRequest.currentRequest.attachments &&
                                                                    currentRequest.currentRequest.attachments.length &&
                                                                    currentRequest.currentRequest.attachments.map(
                                                                        (el, i) => {
                                                                            return (
                                                                                <div key={i}>
                                                                                    {/*TODO REFACTOR LINK*/}
                                                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                                    <a
                                                                                        style={{ cursor: "pointer" }}
                                                                                        target="_blank"
                                                                                        // href={el.url}
                                                                                        // href={el.blobLink}
                                                                                        onClick={() => {
                                                                                            // console.log("EL: ", el);
                                                                                            return RequestServices
                                                                                                .downloadRequestFile(
                                                                                                    el,
                                                                                                    el.leaveRequestUid,
                                                                                                    el.id,
                                                                                                    el.url,
                                                                                                );
                                                                                        }}
                                                                                        className="custom-uploader__item__name"
                                                                                        rel="noreferrer">
                                                                                        {
                                                                                            el.fileName.shortName
                                                                                                ? el.fileName.shortName
                                                                                                : el.fileName
                                                                                        }
                                                                                    </a>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                            </div>
                                                        </div>)
                                                }
                                            </>)
                                            : (<>
                                                {
                                                    currentRequest &&
                                                            Object.keys(currentRequest).map(
                                                                (el, index) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className="request-modal__table__line"
                                                                        >
                                                                            <div className="left-column">
                                                                                {el}
                                                                            </div>
                                                                            <div className="right-column">
                                                                                {currentRequest[el]}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                },
                                                            )}
                                            </>
                                            )}
                            </div>
                            {/*</table>*/}
                            <div className={"buttons-wrapper"}>
                                {
                                    !props.isLeader
                                        ? (!props.isReferense
                                            ? (<>
                                                {
                                                    currentRequest.currentRequest.status === 1
                                                        ? (<>
                                                            <UButton
                                                                className="request-modal__button"
                                                                disabled={currentRequest.currentRequest.status !== 1}
                                                                onClick={() =>
                                                                    goToRefactor(
                                                                        currentRequest.currentRequest,
                                                                    )
                                                                }
                                                            >редактировать</UButton>
                                                        </>)
                                                        : (<>
                                                            <UButton
                                                                className="request-modal__button"
                                                                disabled={
                                                                    currentRequest.currentRequest.status === 2 ||
                                                                            currentRequest.currentRequest.status >= 6 ||
                                                                            currentRequest.currentRequest.status === 4 ||
                                                                            currentRequest.currentRequest.trainingId
                                                                }
                                                                onClick={() =>
                                                                    deleteHandler(
                                                                        "cancel",
                                                                        currentRequest.currentRequest.orderNumber,
                                                                        currentRequest.currentRequest.uid,
                                                                    )
                                                                }
                                                            >отозвать заявку</UButton>
                                                        </>
                                                        )}

                                                <UButton
                                                    className="request-modal__button"
                                                    onClick={() =>
                                                        deleteHandler(
                                                            "delete",
                                                            currentRequest.currentRequest.orderNumber,
                                                            currentRequest.currentRequest.uid,
                                                        )
                                                    }
                                                    disabled={
                                                        !reject &&
                                                            currentRequest.currentRequest.status !== 1
                                                    }
                                                >удалить заявку</UButton>
                                            </>)
                                            : (<UButton
                                                className="request-modal__button"
                                            >скачать документ</UButton>
                                            ))
                                        : (<>
                                            <UButton
                                                className="request-modal__button"
                                                onClick={
                                                    reject
                                                        ? skipRejectHandler
                                                        : () => setReject(true)
                                                }
                                                disabled={
                                                    !reject &&
                                                        currentRequest.currentRequest.status !== 3
                                                }
                                            >{
                                                    reject
                                                        ? "отмена"
                                                        : "отклонить"
                                                }</UButton>
                                            <UButton
                                                className="request-modal__button"
                                                disabled={
                                                    reject
                                                        ? !input
                                                        : currentRequest.currentRequest.status !== 3
                                                }
                                                onClick={
                                                    reject
                                                        ? () =>
                                                            deleteHandler(
                                                                "reject",
                                                                currentRequest.currentRequest.orderNumber,
                                                                currentRequest.currentRequest.uid,
                                                                input,
                                                            )
                                                        : () =>
                                                            deleteHandler(
                                                                "approve",
                                                                currentRequest.currentRequest.orderNumber,
                                                                currentRequest.currentRequest.uid,
                                                            )
                                                }
                                            >{
                                                    reject
                                                        ? "подтвердить"
                                                        : "согласовать"
                                                }</UButton>
                                        </>)
                                }
                            </div>
                        </>))
                    : (<div
                        style={{
                            margin: "auto",
                            textAlign: "center",
                            width: "400px",
                        }}
                    >
                        <ClipLoader color="#6DCDF6" />
                    </div>
                    )}
        </div>
        );
};

export default CurrentRequestCard;
