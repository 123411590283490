import triangle from "../../../../assets/documents/triangle.svg";
import { isTerminal } from "../../../../constants";

export const applicationsData = [
    {
        id: "head-1",
        title: "тип",
        icon: triangle,
        width: isTerminal ? "89px" : "66px",
    },
    {
        id: "head-2",
        title: "номер заявки",
        icon: "",
        width: isTerminal ? "137px" : "100px",
    },
    {
        id: "head-3",
        title: "наименование",
        icon: "",
        width: isTerminal ? "160px" : "234px",
    },
    {
        id: "head-4",
        title: "статус",
        icon: "",
        width: isTerminal ? "139px" : "150px",
    },
    {
        id: "head-5",
        title: "комментарий",
        icon: "",
        width: isTerminal ? "224px" : "330px",
    },
    {
        id: "head-6",
        title: "Дата",
        icon: "",
        width: isTerminal ? "158px" : "145px",
    },
];
