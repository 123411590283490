import { errorMesage } from "./ErrorMesage";

import { BackArrowButton } from "../componentsTerminal/BackArrowButton/BackArrowButton";
import { isTerminal } from "../../../constants";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import ModalBanOverlay from "../Modal/ModalBanOverlay/ModalBanOverlay";
import WorkerSignType from "../Modal/ModalConditions/UserSignType/WorkerSignType/WorkerSignType";
import NavMenuDiv from "../NavMenuDiv/NavMenuDiv";

import React, { useState } from "react";

isTerminal ? import("./MultiRouteTerminal.scss") : import("./MultiRoute.scss");

const MultiRoute = ({
    data,
    styleParent,
    subHeader,
    breadCrumbs,
    securedMessage,
}) => {
    const [errorModal, setErrorModal] = useState(false);
    const [errDescription] = useState(errorMesage);

    function closeModal() {
        setErrorModal(false);
    }

    if (!isTerminal) {
        return (
            <section className={styleParent}>
                {breadCrumbs ? <Breadcrumbs /> : null}
                {subHeader ? <h3 className="sub-header">{subHeader}</h3> : null}
                {data &&
                    data.map((e, i) => (
                        e.leadersBlock ? (
                            !isTerminal && localStorage.getItem("isLeader") === "true" ?
                                <NavMenuDiv
                                    key={i}
                                    path={e.path}
                                    title={e.title}
                                    description={e.description ? e.description : ""}
                                    icon={e.icon}
                                    styleParent={styleParent}
                                    secured={e.secured ? e.secured : false}
                                    unep={e.unep ? e.unep : false}
                                    setErrorModal={setErrorModal}
                                /> : null
                        )

                            : <NavMenuDiv
                                key={i}
                                path={e.path}
                                title={e.title}
                                description={e.description ? e.description : ""}
                                icon={e.icon}
                                styleParent={styleParent}
                                secured={e.secured ? e.secured : false}
                                unep={e.unep ? e.unep : false}
                                setErrorModal={setErrorModal}
                            />

                    ))}
                <ModalBanOverlay shown={errorModal} close={setErrorModal}>
                    <WorkerSignType
                        close={closeModal}
                        description={
                            securedMessage ? securedMessage : errDescription
                        }
                        setErrorModal={setErrorModal}
                    />
                </ModalBanOverlay>
            </section>
        );
    }

    if (isTerminal) {
        return (
            <section className={styleParent}>
                {breadCrumbs && <Breadcrumbs />}
                {subHeader && (
                    <div className="sub-header-wrapper">
                        <BackArrowButton />
                        <h3 className="sub-header">{subHeader}</h3>
                    </div>
                )}
                {data && (
                    <div className="container__content">
                        {data.map((e, i) => (
                            <NavMenuDiv
                                key={i}
                                path={e.path}
                                title={e.title}
                                description={e.description ? e.description : ""}
                                icon={e.icon}
                                styleParent={styleParent}
                                secured={e.secured ? e.secured : false}
                                unep={e.unep ? e.unep : false}
                                setErrorModal={setErrorModal}
                            />
                        ))}
                    </div>
                )}
                <ModalBanOverlay shown={errorModal} close={setErrorModal}>
                    <WorkerSignType
                        close={closeModal}
                        description={
                            securedMessage ? securedMessage : errDescription
                        }
                        setErrorModal={setErrorModal}
                    />
                </ModalBanOverlay>
            </section>
        );
    }
};

export default MultiRoute;
