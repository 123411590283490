import { applicationsData } from "./applications.data";

import ApproveRequest from "./Modal/ApproveRequest/ApproveRequest";

import approveRequest from "./Modal/ApproveRequest/ApproveRequest";

import { Modal } from "../../Modal/Modal";
import { isTerminal } from "../../../../constants";

import {
    setCurrentRequestThunkAction,
} from "../../../../redux/actions/Requests/CurrentRequest/thunk/setCurrentRequest.thunk.action";
import RequestServices from "../../../../frontend.services/RequestServices/RequestServices";
import { unsetUserRequestAction } from "../../../../redux/actions/Requests/CurrentRequest/unsetUserRequest.action";
import UserRequestCard from "../ModalAssets/UserRequestCard/UserRequestCard";

import { LeftArrow } from "../../../../assets/icons/LeftArrow";
import { RightArrow } from "../../../../assets/icons/RightArrow";

import CustomLoader from "../../CustomLoader/CustomLoader";

import UButton from "../../Button/UButton/UButton";

import RecruitmentToWorkOnHolidaysModal
    from "../../../pages/HRServicesPage/LeaderRequestsPage/LRPage/RecruitmentToWorkOnHolidaysModal/RecruitmentToWorkOnHolidaysModal";

import {
    getGroupCurrentRequestThunkAction,
} from "../../../../redux/actions/CurrentDocument/thunk/getGroupCurrentRequest.thunk.action";

import StageValidationHOC from "../../../../hocs/StageValidation.HOC";

import RequestConfirmation from "../../Modal/ModalConditions/Requests/RequestConfirmation/RequestConfirmation";

import ModalSuccess
    from "../../../pages/HRServicesPage/LeaderRequestsPage/LRPage/RecruitmentToWorkOnHolidays/ModalSuccess/ModalSuccess";

import { $api } from "../../../../redux/services/request.config";

import {
    setCurrentDetailsAction,
} from "../../../../redux/actions/Documents/Leader/testDetails/setCurrentDetails.action";

import { mode } from "../../../../app/mode";

import RecruitmentToWorkOnHolidays
    from "../../../pages/HRServicesPage/LeaderRequestsPage/LRPage/RecruitmentToWorkOnHolidays/RecruitmentToWorkOnHolidays";

import {
    sendMemoThunkAction,
} from "../../../../redux/actions/Requests/RequestsFromLeader/RecruitmentToWork/thunk/sendMemo.thunk.action";

import { ClipLoader } from "react-spinners";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";

// <ModalBanOverlay shown={true}>
//     <div style={{
//         display: "flex",
//         justifyContent: "center",
//         flexDirection: "column",
//         alignItems: "center",
//         background: "#FFFFFF",
//         minHeight: "40vh",
//         width: "70vw",
//         textAlign: "center",
//         padding: "20px",
//         gap: "30px",
//     }}>  </div>
//
// </ModalBanOverlay>


isTerminal ? import("./ApplicationsTerminal.scss") : import("./Applications.scss");

const Applications = (
    {
        currentRowsHandler,
        rowsPerPage,
        userRequests,
    },
) => {

    const dispatch = useDispatch();

    // TEST DELETE!!!!!
    const [modalStatus, setModalStatus] = useState(false);
    const [stageModalWindow, setStageModalWindow] = useState(false);

    const rvdDetails = useSelector((state) => state.documents.rvdDetails);
    const [sendButtonLoading, setSendButtonLoading] = useState(false);

    const [selectedE, setSelectedE] = useState([]);

    const approveRequest = {
        back: () => {
            setStageModalWindow(null);
        },
        next: async (newTuple, props) => {
            console.log("Work");


            try {


                // const response = await $api.post("v1/auth/verify/password", { password: password.password });
                // console.log("data:  ", response.data);
                newTuple[0][0].props.description = {
                    head: "Служебная записка принята",
                    subHead: " Служебная записка\n" +
                        "                               на тему  «Привлечение к\n" +
                        "                                работе в выходные\n" +
                        "                                и\n" +
                        "                                праздничные дни»\n" +
                        "                                принята.",
                };
                setStageModalWindow(StageValidationHOC(newTuple));
            } catch (e) {

            }
        },
        props: {
            rvdDetails: rvdDetails,
            pids: selectedE,


        },
    };
    const actionsOK = {
        back: () => {
            console.log("BACK");
            setStageModalWindow(null);
        },
        next: (newTuple) => {
            console.log("NEXT", newTuple);
            // dispatch(sendMemoThunkAction());
            // newTuple[1][0].props.name = "NEW HOC";
            // setStageModalWindow(StageValidationHOC(newTuple));
        },
        props: {
            name: "testHOC",
            rvdDetails: rvdDetails,
            pids: selectedE,


        },
        // newTuple: null,
    };
    const testHOC = StageValidationHOC([
        [approveRequest, ApproveRequest],
        [actionsOK, ModalSuccess],
        // [actionsOK, ModalSuccess],


    ]);

    function detailsClickHandler() {
        rvdDetails && console.log("rvdDetails", rvdDetails);
        rvdDetails &&
        dispatch(getGroupCurrentRequestThunkAction(rvdDetails.uid, {}));
        setModalStatus(true);
        // setStageModalWindow(testHOC);

    }

    function testDetails(uid) {
        dispatch(getGroupCurrentRequestThunkAction(uid, {}));
        setModalStatus(true);
        approveRequest.props.rvdDetails = uid;
        actionsOK.props.rvdDetails = uid;


    }

    // // DELETE

    const [modalShownReferences, toggleModalShownReferences] = useState(false);
    const [description, setDescription] = useState("");
    const [itemOffset, setItemOffset] = useState(0);


    const endOffset = itemOffset + rowsPerPage;
    const currentItems = userRequests.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(userRequests?.length / rowsPerPage);

    function clickHandlerReferences(document, uid, i) {
        toggleModalShownReferences(!modalShownReferences);
        dispatch(setCurrentRequestThunkAction(uid, i, { setDescription: setDescription }));
    }

    function closeHandlerReferences(e) {
        toggleModalShownReferences(false);
        dispatch(unsetUserRequestAction());
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * rowsPerPage) % userRequests.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        if (itemOffset < rowsPerPage) setItemOffset(0);
        setItemOffset(Math.floor(itemOffset / rowsPerPage) * rowsPerPage);
    }, [itemOffset, rowsPerPage]);


    return !userRequests
        ? <CustomLoader />
        : (<div>
            <div className="table-container">

                <table className="table">
                    <thead>
                        <tr className="table__row">
                            {applicationsData && applicationsData.map((el) => {
                                return (
                                    <th
                                        key={el.id}
                                        style={{ width: `${el.width}` }}
                                        className="table__row__item"
                                    >
                                        {el.title}
                                        {
                                            el.icon
                                                ? <img
                                                    src={el.icon}
                                                    alt="triangle"
                                                    className="header-triangle" />
                                                : null
                                        }
                                    </th>
                                );
                            })
                            }
                        </tr>
                    </thead>
                    {/*<tr>*/}
                    <tbody>
                        {currentItems && currentItems.map((el, i) => (
                            <tr
                                onClick={() => (clickHandlerReferences(el, el.uid, i))}
                                key={el.id}
                                className={el.isReviewed ? "row-request" : "row-request-active"}
                            >
                                <td style={{ width: isTerminal ? "89px" : "66px" }}>{el.typeText}</td>
                                <td style={{ width: isTerminal ? "137px" : "100px" }}>{el.orderNumber}</td>
                                <td style={{ width: isTerminal ? "160px" : "234px" }}>{el.leaveReasonId}</td>
                                <td style={{ width: isTerminal ? "139px" : "150px" }}>{el.statusText}</td>
                                <td style={{ width: isTerminal ? "224px" : "330px" }}>
                                    {
                                        el.employeeComment
                                            ? (el.employeeComment.length > 35
                                                ? el.employeeComment.slice(0, 35) + "..."
                                                : el.employeeComment)
                                            : "..."
                                    }
                                </td>
                                <td
                                    // onClick={(e) => e.stopPropagation()}
                                    style={{ width: isTerminal ? "158px" : "145px" }}>
                                    {moment(el.createDate).format("DD.MM.YYYY")}
                                    {/*{el.fileName ?*/}
                                    {/*// eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                    {/*    <a*/}
                                    {/*    // TODO REFACTOR LINK!!!*/}
                                    {/*    // href="/#"*/}
                                    {/*        download*/}
                                    {/*        target="_blank"*/}
                                    {/*        onClick={*/}
                                    {/*            () => {*/}
                                    {/*                return RequestServices*/}
                                    {/*                    .appFileDownloadTEMPORARYmethod(*/}
                                    {/*                        el.applicationDocumentAttachment.leaveRequestUid,*/}
                                    {/*                        el.applicationDocumentAttachment.id,*/}
                                    {/*                        el.applicationDocumentAttachment.url,*/}
                                    {/*                    );*/}
                                    {/*            }*/}
                                    {/*        }*/}
                                    {/*        className="request-colored-link">{el.fileName}*/}
                                    {/*    </a>*/}
                                    {/*    : "..."}*/}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {
                    mode === "UNSTABLE" ?
                        <div style={{ marginTop: "30px", display: "flex" }}>
                            <UButton
                                onClick={detailsClickHandler}
                                className="request-modal__button">
                                    Детали заявки РВД
                            </UButton>

                            {/*<UButton*/}
                            {/*    onClick={() => testDetails("48fe6bc5-2512-4f49-b371-5a1eb9fd229a")}*/}

                            {/*    className="request-modal__button">*/}
                            {/*        сз 1*/}
                            {/*</UButton>*/}

                            {/*<UButton*/}
                            {/*    onClick={() => testDetails("54c8989e-1498-40e6-a412-db244f25be82")}*/}
                            {/*    className="request-modal__button">*/}
                            {/*        сз 2*/}
                            {/*</UButton>*/}

                            {/*<UButton*/}
                            {/*    onClick={() => testDetails("909bd06a-18af-4a15-ac3a-0b3296f389ba")}*/}
                            {/*    className="request-modal__button">*/}
                            {/*        сз 3*/}
                            {/*</UButton>*/}

                            {/*<UButton*/}
                            {/*    onClick={() => testDetails("b09c8bf5-cc25-4920-8eec-142b51bc76d1")}*/}
                            {/*тест поля*/}
                            {/*    className="request-modal__button">*/}
                            {/*        рвд 1*/}
                            {/*</UButton>*/}

                            <UButton
                                onClick={() => testDetails("27d1dfe6-470f-48f9-9fc3-951447b95332")}
                                className="request-modal__button">
                                    рвд 1
                            </UButton>

                            {/*<UButton*/}
                            {/*    onClick={() => testDetails("c4603365-5a9c-46d8-8f7a-4535fe18d626")}*/}
                            {/*    className="request-modal__button">*/}
                            {/*        рвд 1*/}
                            {/*</UButton>*/}


                            {/*<UButton*/}
                            {/*    onClick={() => testDetails("419af639-ea66-4267-b03d-4cb6513906f4")}*/}
                            {/*    // тест Поля*/}
                            {/*    className="request-modal__button">*/}
                            {/*        рвд 2*/}
                            {/*</UButton>*/}

                            {/*<UButton*/}
                            {/*    onClick={() => testDetails("1c8715c2-aca9-4b80-af28-31d0f88261dd")}*/}
                            {/*    className="request-modal__button">*/}
                            {/*        рвд 2*/}
                            {/*</UButton>*/}

                            <UButton
                                onClick={() => testDetails("2dd94332-3319-4544-b7e5-0d7804104d3f")}
                                className="request-modal__button">
                                    рвд 2
                            </UButton>

                        </div>
                        : null
                }


                {
                    !userRequests.length
                        ? <div>

                        </div>
                        : <div className={isTerminal && "pagination-wrapper"}>
                            {!isTerminal && <div className="pagination-container">
                                <div className="pagination-container__rows">
                                    <div
                                        onClick={() => currentRowsHandler(5)}
                                        className={rowsPerPage === 5 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                            5
                                    </div>
                                    <div
                                        onClick={() => currentRowsHandler(10)}
                                        className={rowsPerPage === 10 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                            10
                                    </div>
                                    <div
                                        onClick={() => currentRowsHandler(20)}
                                        className={rowsPerPage === 20 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>

                                            20
                                    </div>

                                    <div
                                        onClick={() => currentRowsHandler(50)}
                                        className={rowsPerPage === 50 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                            50
                                    </div>
                                </div>
                            </div>}
                            <ReactPaginate
                                className={"pagination"}
                                breakLabel=".."
                                nextLabel={isTerminal ? <RightArrow /> : ">"}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                pageCount={pageCount}
                                previousLabel={isTerminal ? <LeftArrow /> : "<"}
                                renderOnZeroPageCount={null}
                                forcePage={Math.floor(itemOffset / rowsPerPage)}
                            />
                        </div>


                }
            </div>
            {mode === "UNSTABLE" ? <>  {stageModalWindow}
            </>
                : null
            }

            <Modal
                shown={modalShownReferences}
                close={closeHandlerReferences}>
                <UserRequestCard

                    setDescription={setDescription}
                    description={description}
                    userRequests={userRequests}
                    close={closeHandlerReferences}
                />
            </Modal>
            {
                mode === "UNSTABLE" ?
                    <Modal shown={modalStatus} close={setModalStatus}>
                        <RecruitmentToWorkOnHolidaysModal
                            selectedE={selectedE}
                            setSelectedE={setSelectedE}
                            setStageModalWindow={setStageModalWindow}
                            testHOC={testHOC}
                            setModalStatus={setModalStatus} />
                    </Modal> : null
            }


        </div>
        );
};

export default React.memo(Applications);
