import { data } from "./MainPage.data";
import { dataTerminal } from "./MainPageTerminal.data";

import { isTerminal, iframeProxyEpassport } from "../../../constants";
import MultiRoute from "../../common/MultiRoute/MultiRoute";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import ModalBanOverlay from "../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import WorkerSignType from "../../common/Modal/ModalConditions/UserSignType/WorkerSignType/WorkerSignType";

import UButton from "../../common/Button/UButton/UButton";
import { Modal } from "../../common/Modal/Modal";

import {
    getMaintenanceNotificationThunkAction,
} from "../../../redux/actions/User/Notification/thunk/getMaintenanceNotification.thunk.action";

import AuthService from "../../../redux/services/AuthService";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

isTerminal ? import("./MainPageTerminal.scss") : import("./MainPage.scss");

const style = isTerminal ? null : { marginTop: "30px", minHeight: "40vh" };
const routers = isTerminal ? dataTerminal : data;

const MainPage = () => {
    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState(false);
    const [errDescription, setErrDescription] = useState("");
    const [actualDocumentsModal, setActualDocumentsModal] = useState(false);
    const [isOpen, setOpened] = useState(false);
    const [fio] = useState(JSON.parse(localStorage.getItem("fio")));


    function closeModal() {
        setErrorModal(false);
    }

    useEffect(() => {
        // if (localStorage.getItem("restricted") === "false") {
        if (localStorage.getItem("hasActualDocuments") === "true") {
            setActualDocumentsModal(true);
            setErrorModal(true);
            setErrDescription(
                "Функциональность приложения ограничена." +
                " Чтобы продолжить работу с приложением," +
                " примите решение по всем документам," +
                " требующим ознакомления или подписания",
            );
        }

        // } else if (localStorage.getItem("restricted") === "true") {
        //     setErrorModal(true);
        //     setErrDescription(
        //         localStorage.getItem("reason"),
        //     );
        // }

    }, []);
    useEffect(() => {
        dispatch(getMaintenanceNotificationThunkAction());
    }, []);
    useEffect(() => {
        if (!localStorage.getItem("appointment") && fio) {
            AuthService.getHeaderInformation().then(r => console.log(r));
        }
    }, []);
    return (
        <>
            <Header
                errorModal={errorModal}
                setErrorModal={setErrorModal}
                errDescription={errDescription}
                setErrDescription={setErrDescription}
            />
            <section className={"mainPage"} style={style}>
                <div>
                    <MultiRoute
                        breadCrumbs={false}
                        data={routers}
                        styleParent={"main-page"}
                        securedMessage={localStorage.getItem("reason")}
                    />
                </div>
                {/*onClick={props.function}*/}
                {/*disabled={props.disabled? props.disabled : null}*/}
                {/*className={props.parentStyle}>*/}
                {/*{props.buttonText}*/}
                {!isTerminal && (
                    <UButton
                        className={"mainPage__button"}
                        onClick={() =>
                            window.open(isTerminal ? iframeProxyEpassport : "http://prn.alrosa.ru:5000/epassport")
                        }
                    >Сообщить о технической проблеме</UButton>
                )}
                {/*{isTerminal && (*/}
                {/*    <>*/}
                {/*        <UButton*/}
                {/*            className={"mainPage__button"}*/}
                {/*            onClick={() => setOpened(true)}*/}
                {/*        >Сообщить о технической проблеме</UButton>*/}
                {/*        <Modal shown={isOpen} close={() => setOpened(false)}>*/}
                {/*            <iframe className="mainPage__iframe"*/}
                {/*                src={isTerminal ? iframeProxyEpassport : "http://prn.alrosa.ru:5000/epassport"} />*/}
                {/*        </Modal>*/}
                {/*    </>*/}
                {/*)}*/}
            </section>

            <ModalBanOverlay shown={errorModal} close={setErrorModal}>
                <WorkerSignType
                    close={closeModal}
                    path={"/documents"}
                    description={errDescription}
                    setErrorModal={setErrorModal}
                    closeButton={true}
                    block={actualDocumentsModal ? true : null}
                />
            </ModalBanOverlay>
            <Footer />
        </>
    );
};

export default MainPage;
