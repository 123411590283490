import $apiMultipart from "../../../../services/axiosMultipartService";
import { GET_ATTACHMENTS_TO_REQUEST } from "../../../../enums/Request/request.types";
import { addOneAttachmentAction } from "../../currentRequestControls/addOneAttachment.action";

export const newUploadFileThunkAction =
    ({ file, setIsUploading }, index, type, i, field, singleUpload) =>
        async (dispatch) => {
            try {
                setIsUploading && setIsUploading(true);
                const response = await $apiMultipart.post(
                    "v1/requests/attachments",
                    file,
                );
                if (response.status === 201) {
                    const temp = { ...response.data };
                    temp.type = type ? type : null;
                    if (field) {
                        const data = {
                            doc: temp,
                            ind: index,
                            indexAttach: i,
                            attachField: field,
                        };
                        dispatch({
                            type: GET_ATTACHMENTS_TO_REQUEST,
                            payload: data,
                        });
                        setIsUploading && setIsUploading(false);
                    } else {
                        const data = {
                            document: temp,
                            indexOfAttach: i,
                            singleUpload: singleUpload,
                        };
                        dispatch(addOneAttachmentAction(data));
                        setIsUploading && setIsUploading(false);
                    }

                }
            } catch (error) {
                setIsUploading && setIsUploading(false);
            }
        };
