import blankList from "../../../assets/icons/hr-services/ic_worktime.svg";
import calendar from "../../../assets/icons/hr-services/ic_reward.svg";
import blankNote from "../../../assets/icons/hr-services/ic_benefits.svg";
import users from "../../../assets/icons/hr-services/ic_health.svg";
import classBoard from "../../../assets/icons/hr-services/ic_avans.svg";
import fingerprint from "../../../assets/icons/hr-services/ic_assingments.svg";
import deleteIcon from "../../../assets/icons/hr-services/ic_dismissal.svg";
import blank from "../../../assets/icons/NavBlockIcons/blank-list.svg";

export const data = [
    {
        title: "Рабочее время",
        description:
            "График работы, отпуска, и другие данные о рабочем времени ",
        icon: blankList,
        path: "/main/hr-services/work-time",
    },
    {
        title: "Вознаграждения",
        description: "Оклад, премии, бонусы и другие данные о вознаграждении",
        icon: calendar,
        // path: "/main/hr-services/reward",
        path: "#",

    },
    {
        title: "Льготы",
        description: "Пособия, материальная помощь и другие данные о льготах",
        icon: blankNote,
        path: "#",
    },
    {
        title: "Здоровье",
        description:
            "Вакцинация, медицинский осмотр и другие данные о здоровье",
        icon: users,
        path: "#",
    },
    {
        title: "Увольнение",
        description: "Оформление и документы для увольнения",
        icon: deleteIcon,
        path: "/main/hr-services/layoff",
    },
    {
        title: "Обучение",
        description:
            "Повышение квалификации курсы, семинары, и другие данные об обучении",
        icon: blankNote,
        path: "#",
    },
    // {
    //     title: "Больничные",
    //     description: "Регистрация больничных листов",
    //     icon: users,
    //     path: "#",
    // },
    {
        title: "Авансовые отчеты",
        description: "Оформление авансовых отчетов",
        icon: classBoard,
        path: "#",
    },
    {
        title: "Командировки",
        description: "Оформление командировок",
        icon: fingerprint,
        path: "#",
    },
    {
        title: "Заявки руководителя",
        description: "Отпуска, делегирование, служебные записки и другие",
        icon: blank,
        path: "/main/hr-services/leader-requests",
        leadersBlock: true,
    },

];
