import { $api } from "../../../../services/request.config";

const FileSaver = require("file-saver");


export const downloadDocumentThunkAction = (file) => async (dispatch) => {
    try {
        console.log(file);
        const document = await $api.get(
            `${file.url}`,
            { responseType: "blob" },
        );
        const blobLink = URL.createObjectURL(
            new Blob([document.data],
                { type: `${document.headers["content-type"]}` },
            ),
        );
        // if (file.extension) {
        //     console.log(file);
        // }
        if (file.id === 2 || file.id === 3) {
            FileSaver.saveAs(blobLink, `${file.documentName}_${file.name}.sig`);
        } else {
            FileSaver.saveAs(blobLink, `${file.documentName}_${file.name}.${file.extension}`);
        }

    } catch (e) {
        console.log("error");
    }
};
