import { unsetUserAction } from "../../redux/actions/Auth/userLoginAction";
import { noAuthAction } from "../../redux/actions/Auth/Thunk/checkAuthThunkAction";
import { removeCookiesThunkAction } from "../../redux/actions/Auth/Thunk/removeCookies.thunk.action";

import { unsetPersonalData } from "../../redux/actions/User/LogOut/userLogOutAction";

import { unsetMealCardBalanceAction } from "../../redux/actions/User/profile/Widgets/getFoodCardBalance.action";

import { useCallback } from "react";
import { useDispatch } from "react-redux";

export function useLogout(navigate) {
    const dispatch = useDispatch();

    const logout = useCallback(() => {
        dispatch(unsetUserAction());
        dispatch(noAuthAction());
        dispatch(removeCookiesThunkAction());
        dispatch(unsetPersonalData());
        dispatch(unsetMealCardBalanceAction());
        localStorage.removeItem("userId");
        localStorage.removeItem("resetåPassword");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("fio");
        localStorage.removeItem("appointment");
        localStorage.removeItem("isLeader");
        localStorage.removeItem("workerSignType");
        localStorage.removeItem("documentSignType");
        localStorage.removeItem("agreement");
        localStorage.removeItem("hasActualDocuments");

        if (navigate) {
            navigate("/start");
        } else if (window.location.pathname !== "/start") {
            window.location.replace("/start");
        }
    }, [navigate]);

    return logout;
}
