import RequestTab from "./RequestTab/RequestTab";

import GeneralInformation from "./GeneralInformation/GeneralInformation";
import SelectedChild from "./SelectedChild/SelectedChild";

import bluePlus from "../../../../../../common/Inputs/DatePeriodControlled/BluePlusCircle.svg";

import redPlus from "../../../../../../common/Inputs/DatePeriodControlled/RedRotatedPlusCircle.svg";

import {
    getParentalStatusThunkAction,
} from "../../../../../../../redux/actions/Requests/ParentalLeave/thunk/getParentalStatus.thunk.action";
import {
    getClaimantStatusesThunkAction,
} from "../../../../../../../redux/actions/Requests/ParentalLeave/thunk/getClaimantStatuses.thunk.action";
import {
    clearAttachmentsAction,
} from "../../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import {
    emptyCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import ParentalLeaveApplication from "../../../../../../../frontend.classes/Requests/ParentalLeave.application";
import {
    addChildrenAction,
} from "../../../../../../../redux/actions/Requests/ParentalLeave/controls/addChildren.action";
import {
    deleteChildrenAction,
} from "../../../../../../../redux/actions/Requests/ParentalLeave/controls/deleteChildren.action";
import {
    getUserAddressesThunkAction,
} from "../../../../../../../redux/actions/User/profile/GeneralData/thunk/getUserAddresses.thunk.action";
import {
    getUserPassportThunkAction,
} from "../../../../../../../redux/actions/User/profile/GeneralData/thunk/getUserPassport.thunk.action";
import NotificationWindowRequests from "../../../../../../common/NotificationWindowRequests/NotificationWindowRequests";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import React from "react";
import { v4 as uuidv4 } from "uuid";

import "./ParentalLeave.scss";


const ParentalLeave = ({
    setRequestStatus,
    requestStatus,
    // requestOption,
}) => {

    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const currentRequest = useSelector(state => state.requests.currentRequest);

    const [notificationWindow, setNotificationWindow] = useState(false);
    const [tab, setTab] = useState(-1);

    // function selectDropdown(event, element, stateProperty) {
    //     dispatch(dropdownSelectionCurrentRequest({ event, element, stateProperty }));
    // }

    // function commentHandler(e) {
    //     dispatch(addCommentHandlerCurrentRequest(e.target.value));
    // }

    function addChildren(index) {
        dispatch(addChildrenAction(index));
    }

    function deleteChildren(index) {
        if (index === 0) {
            setTab(-1);
        }
        dispatch(deleteChildrenAction(index));
    }

    // function changeDateHandler(field, date) {
    //     dispatch(validationControlCurrentRequest(field, true));
    //     const value = moment(date).format("YYYY-MM-DD");
    //     dispatch(calendarDatePickCurrentRequest({ field: field, date: value }));
    //     dispatch(validationControlCurrentRequest("dateTo", true));
    //     dispatch(calendarDatePickCurrentRequest({ field: "dateTo", date: moment(value).add(36, "months") }));
    // }

    useEffect(() => {
        setRequestStatus(false);
        dispatch(getUserAddressesThunkAction());
        dispatch(clearAttachmentsAction());
        dispatch(getParentalStatusThunkAction());
        dispatch(getClaimantStatusesThunkAction());
        dispatch(getUserPassportThunkAction());
        if (!queryId) {
            dispatch(emptyCurrentRequest
            (new ParentalLeaveApplication
            ({
                leaveTypeId: 7,
                tabs: [
                    {
                        title: "Общая информация",
                        id: 0,
                        default: false,
                    },
                    {
                        title: "Добавить ребенка",
                        id: 1,
                        default: true,
                    },
                ],
                children: [],
                category: 1,
                relativeId: null,
                isSingleParent: null,
                parentsAlrosaStatusId: null,
                cardNumber: null,
                accountNumber: null,
                bik: null,
                passportSeries: null,
                passportNumber: null,
                parents: [],
                dateTo: null,
                dateFrom: null,
                actualAddress: null,
                registrationAddress: null,
                leaveReasonId: " ",
                attachments: [],
            }),
            ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);

    return (
        <section className={"parental-leave"}>
            <div className={"parental-leave__tabs"}>
                <RequestTab
                    parentStyle={tab === -1 ? "parental-leave__tabs__active-tab" : "parental-leave__tabs__tab"}
                    tittle={"Общая информация"}
                    img={false}
                    func={() => setTab(-1)}

                />
                {
                    currentRequest &&
                    currentRequest.children &&
                    currentRequest.children.length > 0 &&
                    currentRequest.children.map((element, index) => {
                        return (
                            <RequestTab
                                currentRequest={currentRequest && currentRequest}
                                func={() => setTab(index)}
                                index={index}
                                key={uuidv4()}
                                parentStyle={tab === index ? "parental-leave__tabs__active-tab" : "parental-leave__tabs__tab"}
                                field={"children"}
                                img={redPlus}
                                del={true}
                                handler={deleteChildren}
                            />
                        );
                    })
                }
                <RequestTab
                    func={() => setTab(tab + 1)}
                    parentStyle={"parental-leave__tabs__tab"}
                    tittle={"Добавить ребенка"}
                    img={bluePlus}
                    handler={addChildren}
                />
            </div>
            <GeneralInformation
                visible={tab === -1 ? true : false}
                currentRequest={currentRequest}
                parentStyle={"parental-leave"}
            />
            {
                currentRequest &&
                currentRequest.children &&
                currentRequest.children.map((element, index) => {
                    return (
                        <SelectedChild
                            parentStyle={"parental-leave"}
                            currentRequest={currentRequest}
                            element={element}
                            index={tab}
                            visible={tab === index ? true : false}
                        />
                    );
                })
            }


            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
                blockingMessages={true}
            />

        </section>
    );
};

export default ParentalLeave;
